<template>
    <div class="accordion" :id="id">
        <template v-for="item in slots.default()">
            <component :is="item" :parentId = "id"></component>
        </template>
        
    </div>
</template>
<script setup>
    import {useSlots} from 'vue';
    const id = "id_acordion_"+crypto.randomUUID();
    const slots = useSlots();
    
</script>
